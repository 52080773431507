import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import ModalTw from "./ModalTailWind"

export default function TeamSummary({ data }) {
  const [SelectedData, setSelectedData] = useState(null)
  const onClickHandler = async person => {
    setSelectedData(person)
  }
  const closeModal = () => {
    setSelectedData(null)
  }

  return (
    <div className="bg-white" id="bruno">
      {SelectedData?.Description && (
        <ModalTw person={SelectedData} close={closeModal} />
      )}
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
          <div className="space-y-5 sm:space-y-4">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
              Our Team
            </h2>
            <p className="text-xl text-gray-500">
              Thanks to a team of talented people in Europe, Bruno Lafon
              Selection sources small wineries and new rising stars that express
              the true image of their terroirs in France, Spain and Italy.
              Relationship with our producers is key and we do our best to meet
              your satisfaction.
            </p>
          </div>
          <div className="lg:col-span-2">
            <ul
              role="list"
              className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8"
            >
              {data.map(person => (
                <li key={person.FullName}>
                  <div className="space-y-4">
                    <div className="aspect-w-3 aspect-h-2">
                      <GatsbyImage
                        image={
                          person.Portrait.localFile.childImageSharp
                            .gatsbyImageData
                        }
                        className="object-cover shadow-lg rounded-lg"
                        alt="Bruno lafon selection team member"
                      />
                    </div>
                    <div className="text-lg leading-6 font-medium space-y-1">
                      <h3>{person.FullName}</h3>
                      <p className="text-red-600">{person.JobDescription}</p>
                    </div>
                    <div className="text-lg">
                      <p className="text-gray-500">
                        {person.Description.slice(0, 249)}..
                      </p>
                    </div>
                    <button onClick={onClickHandler.bind(null, person)}>
                      Read Full Bio
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
