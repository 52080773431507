import React from "react"
import Layout from "../components/layout"

import ImageCover from "../components/ImageCover"
import styled from "styled-components"
import { graphql } from "gatsby"
import TeamSummary from "../components/MeetTeam"

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
  .gatsby-image-wrapper {
    height: 400px;
  }

  @media only screen and (max-width: 832px) {
    padding: 2vh;
  }
`

const AboutUs = ({ data }) => {
  const team = data.allStrapiAbout.nodes[0].TeamMember

  return (
    <Layout relative={false}>
      <ImageCover
        image={
          data.allStrapiAbout.nodes[0].Cover.CoverImage.localFile
            .childImageSharp.gatsbyImageData
        }
        heading={data.allStrapiAbout.nodes[0].Cover.Paragraph.split("\n\n")[1]}
        section={"#bruno"}
        third
        MainCover={true}
        center={true}
      />
      <Wrapper>
        <TeamSummary data={team} />

        {/* {team.map((e, index) =>
          !(index % 2) ? (
            <Jomba
              key={index}
              title={e.JobDescription}
              name={e.FullName}
              description={e.Description}
              image={e.Portrait?.localFile?.childImageSharp?.gatsbyImageData}
            />
          ) : (
            <Jomba
              key={index}
              title={e.JobDescription}
              name={e.FullName}
              description={e.Description}
              image={e.Portrait?.localFile?.childImageSharp?.gatsbyImageData}
              reverse={true}
            />
          )
        )} */}
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query {
    allStrapiAbout {
      nodes {
        TeamMember {
          FullName
          JobDescription
          Description
          Portrait {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 60
                  height: 400
                  width: 400
                  transformOptions: { fit: COVER, cropFocus: CENTER }
                )
              }
            }
          }
        }
        Cover {
          Headline
          Paragraph
          CoverImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 60)
              }
            }
          }
        }
      }
    }
  }
`

export default AboutUs
